@import "../../init/font.settings";
@import "../../init/color.settings";
@import "../../tools/functions/helper.functions";

$default-element-height: 3.5rem;
$default-element-width: 100%;

$main-element-color-focus: #3d83b9;
$button-element-bg-color: #397bad;
$button-element-bg-color-hover: #48a4ec;

$custom-font-family: Roboto,sans-serif;

.line-input {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid black;
}

.inputs {

  //background-color: $color-accent-darker;
  //color: $color-white;
  font-family: $custom-font-family;

  .form-control {
    border-radius: 0;
    background-clip: unset;
    background-color: unset;
    color: $color-white;
    border: 0;
  }

  .custom-form-control {
    border-radius: 0;
    height: $default-element-height;
    width: $default-element-width;
    z-index: 0;
    display: inline-block;
    vertical-align: baseline;
    font: 400 0.83rem $font-family--primary;
    font-size: $base-font-size--large;
    font-weight: 200;
    line-height: 1.75rem;
    border: 1px $color-accent solid;
    background-color: $color-white;
    color: $color-accent-darker;
  }

  .question-btn-input-wrapper {
    margin: 0;
    padding: 0;
    position: relative;
  }

  .btn-inner {
    position: absolute;
    top: 10px;
    right: 5px;
    margin-left: 10px;
  }

  .custom-form-control:focus {
    outline: 3px $color-accent solid;

  }

  .custom-form-control:focus~ .btn-inner {
    display: none;
  }

  .custom-input {
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
  }

  .custom-select-custom:focus {
    background: $color-white get-svg-uri-by-name('svg-triangle-up') no-repeat right 5px center;
    background-size: 10px 5px;
    outline: 3px $color-accent solid;
  }

  .custom-select-custom {
    font: 400 0.83rem $font-family--primary;
    border-radius: 0;
    height: $default-element-height;
    width: $default-element-width;
    z-index: 0;
    display: inline-block;
    vertical-align: baseline;
    background: $color-white get-svg-uri-by-name('svg-triangle-down') no-repeat right 5px center;
    background-size: 10px 5px;
    font-size: $base-font-size--large;
    font-weight: 200;
    line-height: 1.75rem;
    //margin: 6px 0;
    padding: 0 15px;
    border: 1px solid $color-accent;

    option {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: $color-accent-even-muted;
      border: 0;
      box-shadow: none;
      -webkit-box-shadow: none;
      color: $color-white;
      outline: none 0;
      padding: 4px 8px;
      font-size: $base-font-size--large;
      font-weight: 100;
      line-height: 1;
    }
  }

  input[type="checkbox"] {
    background: #3572a1 no-repeat center center;
  }

  .custom-checkbox .custom-control-label::before {
    cursor: pointer;
    position: absolute;
    background: $color-white;
    border-radius: 0;
    border: 1px solid #3572a1;
  }

  .custom-checkbox.is-error {
    color: #d62329;

    .custom-control-label::before {
      border: 1px solid $color-error-border;
    }

    a {
      font-weight: 500;
    }
  }

  .custom-control-label::after, .custom-control-label::before  {
    width: 1.2rem;
    height: 1.2rem;
  }

  .custom-checkbox input[type='checkbox']:checked ~ .custom-control-label::before {
    background: $color-accent no-repeat center center;
  }
  .custom-control-input:indeterminate ~ .custom-control-label::before {
    background: $color-accent no-repeat center center;
    border-color: $color-accent;
  }

  .custom-checkbox.indeterminate {
    .custom-control-label::after {
      background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'><rect width='100' height='100' style='fill:rgb(53,114,161)' /></svg>") no-repeat center;
      background-size: 50% 50%;
    }
  }

  .custom-checkbox input[type='checkbox']:checked:focus ~ .custom-control-label::before {
    outline: 3px solid $color-accent-light;
    overflow: hidden;
  }

  .custom-radio .custom-control-label::before {
    background: $color-white;
    border: 1px solid $color-accent;
  }

  .custom-radio input[type='radio']:checked ~ .custom-control-label::before {
    border: 5px solid $color-accent;
  }

  .btn-custom-main {
    height: $default-element-height;
    font-size: 18px;
    padding: 4px 10px;
    line-height: 1;
    font-weight: 300;
    background-color: $button-element-bg-color;
  }

  .btn-custom-main:hover {
    background-color: #48a4ec;
  }

  .btn {
    border: 0;
    border-radius: 0;
    box-sizing: border-box;
    font-weight: 500;
    text-align: center;
    transition: all .3s ease-in-out;
    width: 100%;
  }


  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }

  .is-error {
    .custom-form-control, .custom-select-custom {
      border: 1px solid $color-error-border;
      //color: $color-error-text;
    }
    .custom-form-control:focus, .custom-select-custom:focus {
      outline: 3px solid $color-error-border !important;
    }
    .invalid-feedback {
      display: block;
      color: #ff8080;
    }
  }

  //.is-error:focus {
  //  .custom-form-control:focus {
  //    border: 3px solid $color-error-border !important;
  //  }
  //}
}

body {


  .modal-backdrop {
    pointer-events: none;
    background-color: rgba(58,127,180,0.95);
    opacity: unset !important;
  }

}
