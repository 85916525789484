// Typography

$em-base:             16px;
//$base-font-size:      1rem !default;
$small-font-size:     1.4rem !default;
$lead-font-size:      2rem !default;
$title-font-size:     5.2rem !default;
$h1-font-size:        4rem !default;
$h2-font-size:        3rem !default;
$h3-font-size:        2rem !default;
$h4-font-size:        1.7rem !default;
$h5-font-size:        1.5rem !default;
$h6-font-size:        1.3rem !default;
$base-line-height:    1.5 !default;
$heading-line-height: 1.3 !default;
$lead-line-height:    1.7 !default;


// Global Typography Styles
// ---------------------------------------------------------------------------------------------------

$base-font-size--xxsmall    : 10px !default;
$base-font-size--xsmall     : 12px !default;
$base-font-size--small      : 14px !default;
$base-font-size             : 16px !default;
$base-font-size--medium     : 18px !default;
$base-font-size--large      : 20px !default;
$base-font-size--xlarge     : 24px !default;
$base-font-size--xxlarge    : 30px !default;
$base-font-size--huge       : 36px !default;

//$font-family--primary:      'poppins', Arial, sans-serif !default;
$font-family--primary:      'Biotif', Arial, sans-serif !default;
$font-family--secondary:    'Roboto' !default;
$font-family--tertiary:     'EsmeraldaPro' !default;

$font-family--roboto-default: 16px/1.4 Roboto,sans-serif;

$font-weight--lightest:      100 !default;
$font-weight--light-mid:      200 !default;
$font-weight--light:      300 !default;
$font-weight--basic:      400 !default;
$font-weight--normal:     500 !default;
$font-weight--strong:     600 !default;
$font-weight--bold:       700 !default;
$font-weight--extra-bold: 800 !default;

$type-settings: (
        base: (
                font-family:$font-family--primary,
                font-weight:$font-weight--basic
        ),
        base-1: (
                font-family:$font-family--primary,
                font-weight:$font-weight--normal
        ),
        base-2: (
                font-family:$font-family--primary,
                font-weight:$font-weight--strong
        ),
        base-3: (
                font-family:$font-family--primary,
                font-weight:$font-weight--bold
        ),
        base-4: (
                font-family:$font-family--primary,
                font-weight:$font-weight--extra-bold
        ),
        title: (
                font-family:$font-family--secondary,
                font-weight:$font-weight--basic
        ),
        title-1: (
                font-family:$font-family--secondary,
                font-weight:$font-weight--normal
        ),
        title-2: (
                font-family:$font-family--secondary,
                font-weight:$font-weight--strong
        ),
        title-3: (
                font-family:$font-family--secondary,
                font-weight:$font-weight--bold
        ),
        title-4: (
                font-family:$font-family--secondary,
                font-weight:$font-weight--extra-bold
        )

) !default;



