.cos-banner {
  position: relative;

  &__content {
    position: absolute;
    left: 0;
    right: 0;
    padding: 0;

    @extend .l-container;
  }
}

/*.info-bar-details {
  background-color: #003054;
  color: $color-white;
  padding: 5px 0;
}*/

