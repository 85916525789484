//Color Pallet for RCCL

// Color White
$color-white:                #ffffff !default;
$color-white-100:            #DBE5EC !default;
$color-white-200:            #ecf1f4 !default;
$color-white-300:            #f1f1f1 !default;
$color-white-400:            #D9E1E6 !default;
$color-white-500:            #DDE4EB !default;
$color-white-600:            #D8D8D8 !default;
$color-white-700:            #CCCCCC !default;
$color-white-800:            #f5f6fa !default;
$color-white-900:            #fffff5 !default;


// Color Black
$color-black:                #000000 !default;
$color-black-100:            #141415 !default;
$color-black-200:            #21262D !default;
$color-black-300:            #252525 !default;
$color-black-400:            #363636 !default;
$color-black-500:            #464646 !default;


// Color Gray
$color-gray:                 #323a45 !default;
$color-gray-100:             #757575 !default;
$color-gray-200:             #aeb0b5 !default;
$color-gray-300:             #37414A !default;
$color-gray-400:             #2F424D !default;
$color-gray-500:             #959595 !default;
$color-gray-600:             #E1E1E1 !default;
$color-gray-700:             #E6E6E6 !default;
$color-gray-800:             #9e9c9c !default;
$color-gray-900:             #707070 !default;
$color-gray-910:             #E7E7E7 !default;
$color-gray-1000:            #E3E3E3 !default;


// Color Purple
$color-purple:               #4c2c92 !default;
$color-purple-100:           #8B59E2 !default;


// Color Orange
$color-orange:               #F28616 !default;


// Color Red
$color-red:                  #F53E3E !default;
$color-red-100:              #FF9898 !default;
$color-red-200:              #FF6E6E !default;
$color-red-300:              #F45656 !default;
$color-red-400:              #FF9797 !default;
$color-red-500:              #E84118 !default;


// Color Green
$color-green:                #20CD93 !default;
$color-green-100:            #3BCCA4 !default;
$color-green-200:            #1CB2BB !default;
$color-green-300:            #2CA6BD !default;
$color-green-400:            #00BBC2 !default;
$color-green-500:            #00CBFF !default;

// Color Blue
$color-blue:                 #004474 !default;
$color-blue-100:             #1F72EB !default;
$color-blue-200:             #0097E6 !default;
$color-blue-300:             #1673AF !default;
$color-blue-400:             #0d47a1 !default;
$color-blue-500:             #1173A9 !default;
$color-blue-600:             #003E7E !default;
$color-blue-700:             #568CCD !default;
$color-blue-800:             #0F63A5 !default;

// Color Yellow
$color-yellow:               #FFCA63 !default;
$color-yellow-100:           #fbf686 !default;
$color-yellow-200:           #FBC531 !default;
$color-yellow-300:           #FFFEF2 !default;
$color-yellow-400:           #B39047 !default;
$color-yellow-500:           #FFF9C4 !default;


// colors from costco style guide

// grey
$color-dark-grey:            #333333 !default;
$color-dark-med-grey:        #454547 !default;
$color-med-grey:             #666666 !default;
$color-light-grey:           #999999 !default;
$color-light-grey-off:       #bec0c2 !default;
$color-light-med-grey:       #767676 !default;
$color-lightish-grey:        #d2d2d4 !default;
$color-lighter-grey:         #b7b7b7 !default;
$color-superlight-grey:      #d7d7d7 !default;
$color-lightest-grey:        #f6f6f6 !default;

// accent
$color-accent-dark-muted:    #264f6d !default;
$color-accent-darker:        #003054 !default;
$color-accent-dark:          #325d80 !default;
$color-accent:               #3572a1 !default;
$color-accent-med:           #8fceff !default;
$color-accent-light:         #9ec1dc !default;
$color-accent-lighter:       #dae8f5 !default;
$color-accent-lightest:      #f0f5f9 !default;
$color-accent-even-muted:    #476a83 !default;
$color-accent-odd-muted:     #5a7990 !default;
$color-accent-muted:         #678398 !default;
$color-accent-light-muted:   #90a5b5 !default;

// Secondary color
$secondary-color-red:        #d62329 !default;
$secondary-color-green:      #7ea950 !default;
$secondary-color-yellow:     #faf12e !default;
$secondary-color-orange:     #ff9329 !default;

// States
$color-link:                 #3572a1 !default;
$color-link-hover:           #357bb0 !default;

// Scaffolding
$color-border:               #d7d7d7 !default;
$color-text:                 #666666 !default;
$color-text-placeholder:     #bad3e7 !default;
$color-bg-footer:            #0060aa !default;

// Forms / Components
$color-highlight:            #499fc2 !default;
$color-highlight-lighter:    #c7e2eb !default;
$color-highlight-lightest:   #dcf4fd !default;
$color-disabled:             #9c9c9c !default;
$color-disabled-lighter:     #d2d2d4 !default;
$color-attention:            #e0393e !default;
$color-error-background:     #fce8ea !default;
$color-error-border:         #e51837 !default;
$color-error-text:           #e11c38 !default;
$color-area-map:             #2619a2 !default;
$color-information:          #fffa76 !default;
