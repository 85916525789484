/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */

@import "../init/core.settings";
@import "../init/font.settings";
@import "../init/color.settings";

body,html {
  min-height: 100%;
}

/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */

html {
  box-sizing: border-box;
  font: $font-family--roboto-default;
  font-size: 11px !important;
  -webkit-font-smoothing: antialiased;

  //@include set-breakpoint(medium) {
  //  font-size: 16px !important;
  //}
}

* {

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }

}

body {
  color: $color-med-grey;
  background: $color-white;
  font-size: $base-font-size;
  line-height: $base-line-height;
  //font-family: $font-family--primary;
}


/* ==========================================================================
   #SHARED
   ========================================================================== */

/**
 * Shared declarations for certain elements.
 */

body {
  a {
    text-decoration: none;
    color: #82694b;
  }
}

/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */

address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: $inuit-global-spacing-unit;
}



/**
 * Consistent indentation for lists.
 */

dd, ol, ul {
  margin-left: $inuit-global-spacing-unit;
}

input::placeholder {
  opacity: 0.5;
}

/**
 * Undo the red box-shadow glow added by Firefox on invalid inputs.
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-ui-invalid
 */
input:-moz-ui-invalid {
  box-shadow: none;
}
